import React, {lazy, Suspense} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./scss/_global.scss"

/*import Corporate from './pages/corporate/Corporate'
import Ppcproduct from './pages/products/productppc/Ppcproduct'
import Productplus from './pages/products/productplus/Productplus'
import Xtremeweb from './pages/products/productxtreme/Xtremeweb'
import Ordinarypc from './pages/products/productopc/Ordinarypc'
import Managementteam from './pages/managementteam/Managementteam'
import Manageteambio from './pages/manageteambio/Manageteambio'
import Getintouch from './pages/getintouch/Getintouch'
import Career from './pages/career/Career'
import Dlocator from './pages/dlocator/Dlocator'
import Groupcompany from './pages/groupcompany/Groupcompany'
import Annualreturn from './pages/annualreturn/Annualreturn'
import Compliance from './pages/compliance/Compliance'
import Ppolicy from './pages/ppolicy/Ppolicy'
import Csr from './pages/csr/Csr'
import Scrolltotop from './components/scrolltotop/Scrolltotop'
import Sustainability from './pages/sustainability/Sustainability'
import Mbio2 from './pages/manageteambio/Mbio2'
import Mbio3 from './pages/manageteambio/Mbio3'
import Mbio4 from './pages/manageteambio/Mbio4'
import Mbio5 from './pages/manageteambio/Mbio5'
import Mbio6 from './pages/manageteambio/Mbio6'
import Mbio7 from './pages/manageteambio/Mbio7'
import Mbio8 from './pages/manageteambio/Mbio8'
import Mbio9 from './pages/manageteambio/Mbio9'
import Mbio10 from './pages/manageteambio/Mbio10'
import Process from './pages/process/Process'
import Mbio12 from './pages/manageteambio/Mbio12'
import Fof2 from './pages/fof-main/Fof2'
import Thankyou from './pages/thankyou/Thankyou'
import Cookie from './components/cookie/Cookie'
import Farqnazaraayga from './pages/farqnazaraayga/Farqnazaraayga'
import MainHeader from './components/header/MainHeader'
import MainFooter from './components/footer/MainFooter'
import Disclaimer from './pages/Disclaimer/Disclaimer'
import MarvelWonder from './pages/marvelwonder/MarvelWonder';
import TermsAndConditions from './pages/termsandconditions/TermsAndConditions';*/

import Home from './Home';

//const Home = lazy(() => import('./Home' /* webpackPrefetch: true */));
const Corporate = lazy(() => import('./pages/corporate/Corporate' /* webpackPrefetch: true */));
const Ppcproduct = lazy(() => import('./pages/products/productppc/Ppcproduct'));
const Productplus = lazy(() => import('./pages/products/productplus/Productplus'));
const Xtremeweb = lazy(() => import('./pages/products/productxtreme/Xtremeweb'));
const Ordinarypc = lazy(() => import('./pages/products/productopc/Ordinarypc'));
const Managementteam = lazy(() => import('./pages/managementteam/Managementteam'));
const Manageteambio = lazy(() => import('./pages/manageteambio/Manageteambio'));
const Getintouch = lazy(() => import('./pages/getintouch/Getintouch'));
const Career = lazy(() => import('./pages/career/Career'));
const Dlocator = lazy(() => import('./pages/dlocator/Dlocator'));
const Groupcompany = lazy(() => import('./pages/groupcompany/Groupcompany'));
const Annualreturn = lazy(() => import('./pages/annualreturn/Annualreturn'));
const Compliance = lazy(() => import('./pages/compliance/Compliance'));
const Ppolicy = lazy(() => import('./pages/ppolicy/Ppolicy'));
const Csr = lazy(() => import('./pages/csr/Csr'));
const Scrolltotop = lazy(() => import('./components/scrolltotop/Scrolltotop'));
const Sustainability = lazy(() => import('./pages/sustainability/Sustainability'));
const Mbio2 = lazy(() => import('./pages/manageteambio/Mbio2'));
const Mbio3 = lazy(() => import('./pages/manageteambio/Mbio3'));
const Mbio4 = lazy(() => import('./pages/manageteambio/Mbio4'));
const Mbio5 = lazy(() => import('./pages/manageteambio/Mbio5'));
//const Mbio6 = lazy(() => import('./pages/manageteambio/Mbio6'));
const Mbio7 = lazy(() => import('./pages/manageteambio/Mbio7'));
const Mbio8 = lazy(() => import('./pages/manageteambio/Mbio8'));
const Mbio9 = lazy(() => import('./pages/manageteambio/Mbio9'));
const Mbio10 = lazy(() => import('./pages/manageteambio/Mbio10'));
const Process = lazy(() => import('./pages/process/Process'));
const Mbio12 = lazy(() => import('./pages/manageteambio/Mbio12'));
//const Fof2 = lazy(() => import('./pages/fof-main/Fof2' /* webpackPrefetch: true */));
const Thankyou = lazy(() => import('./pages/thankyou/Thankyou'));
const Cookie = lazy(() => import('./components/cookie/Cookie'));
const Farqnazaraayga = lazy(() => import('./pages/farqnazaraayga/Farqnazaraayga'));
const MainHeader = lazy(() => import('./components/header/MainHeader'));
const MainFooter = lazy(() => import('./components/footer/MainFooter'));
const Disclaimer = lazy(() => import('./pages/Disclaimer/Disclaimer'));
const MarvelWonder = lazy(() => import('./pages/marvelwonder/MarvelWonder'));
const TermsAndConditions = lazy(() => import('./pages/termsandconditions/TermsAndConditions'));

const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Scrolltotop />
        <MainHeader />
        <Suspense>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/corporate' element={<Corporate />}/>
          <Route path='/ppc' element={<Ppcproduct />}/>
          <Route path='/xtreme' element={<Xtremeweb />}/>
          <Route path='/opc' element={<Ordinarypc />}/>
          <Route path='/plus' element={<Productplus />}/>
          <Route path='/mteam' element={<Managementteam />}/>
          <Route path='/suresh-patni' element={<Manageteambio />}/>
          <Route path='/ashok-patni' element={<Mbio2 />}/>
          <Route path='/vimal-patni' element={<Mbio3 />}/>
          <Route path='/vineet-patni' element={<Mbio4 />}/>
          <Route path='/vivek-patni' element={<Mbio5 />}/>
          {/*<Route path='/ibrahim-ali' element={<Mbio6 />}/>*/}
          <Route path='/kiran-patil' element={<Mbio7 />} />
          <Route path='/parmanand-patidar' element={<Mbio8 />} />
          <Route path='/ravindra-mohnot' element={<Mbio9 />} />
          <Route path='/tarun-singh-chauhan' element={<Mbio10 />} />
          <Route path='/deepak-mehra' element={<Mbio12 />} />
          <Route path='/get-in-touch' element={<Getintouch />}/>
          <Route path='/careers' element={<Career />}/>
          <Route path='/dlocator' element={<Dlocator />}/>
          <Route path='/g-company' element={<Groupcompany />}/>
          <Route path='/annual-return' element={<Annualreturn />}/>
          <Route path='/compliance' element={<Compliance />}/>
          <Route path='/privacy-policy' element={<Ppolicy />}/>
          <Route path='/csr' element={<Csr />}/>
          <Route path='/sustainability' element={<Sustainability />}/>
          <Route path='/process' element={<Process />}/>
          <Route path='/thank-you' element={<Thankyou />} />
          <Route path='*' element={<Home />} />
          <Route path='/farqnazaraayega' element={<Farqnazaraayga />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/aboutus' element={ <Navigate to="/corporate" /> }/>
          <Route path='/m/careers' element={ <Navigate to="/careers" /> }/>
          <Route path='/contactus' element={ <Navigate to="/get-in-touch" /> }/>
          <Route path='/our-process' element={ <Navigate to="/process" /> }/>
          <Route path='/pdf/CSR%20Projects%20Approved%20by%20the%20Board.pdf' element={ <Navigate to='/compliance_doc/2020-21/CSR/CSR%20Projects%20Approved%20by%20the%20Board%20FY%202020-21.pdf'/> }/>
          <Route path='/csr-projects-approved-by-the-board.html' element={ <Navigate to="/compliance_doc/2021-22/CSR/CSR%20Projects%20Approved%20by%20the%20Board%20FY%202021-22.pdf" /> }/>
          <Route path='/marvels-of-wonder' element={<MarvelWonder />} />
          <Route path='/wonder-ke-superstar' element={<TermsAndConditions />} />
        </Routes>
        </Suspense>
        <Cookie />
        <MainFooter />
      </BrowserRouter>
    </>
  )
}

export default App
